
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@headlessui/react';
import { useFetchData } from '../../hooks/hooks';
import { GetProducts } from '../../routes/routes';
import {useParams} from "react-router-dom";
import { useEffect, useState, Fragment } from 'react';
import ProductView from '../../pages/productView';


/**
 * Renders a drop-down menu with offers and a modal for viewing more details about an offer.
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.data - The array of offer data.
 * @param {Function} props.fetchData - The function to fetch offer data.
 *
 * @return {JSX.Element} - The rendered drop-down menu and modal.
 */
export default function DropDownOffer(props) {
    const { id } = useParams();
    const { data, fetchData } = useFetchData(GetProducts(id));
    const [isLoading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (typeof fetchData === 'function') {
            fetchData();
        }
    }, [fetchData]);

    useEffect(() => {
        if (data) setLoading(false);
    }, [data]);

    const openModal = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
    };

    if (isLoading) return <div>Loading...</div>;

    // IDs to filter for available offers
    const allowedIDs = [8, 10, 31, 34, 35, 48, 49];

    return (
        <>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button
                        onClick={fetchData}
                        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        Offers
                        <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                    </Menu.Button>
                </div>

                <Menu.Items
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <div className="py-1">
                        {data !== null && Array.isArray(data) && data.length > 0 && data
                            .filter(item => allowedIDs.includes(item.id))  // Filter items by allowed IDs
                            .map((item) => (
                            <Menu.Item as="div" key={item.id} className="px-4 py-2 flex justify-between items-center">
                                <div
                                    onMouseEnter={() => props.updateSelectedLot(item)}
                                    className="text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                >
                                    <b>{item.title}</b> - ${item.price}
                                </div>
                                <button
                                    onClick={() => openModal(item)}
                                    className="text-sm text-indigo-500 hover:text-indigo-700"
                                >
                                    View
                                </button>
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Menu>

            {/* Modal for ProductView */}
            {isModalOpen && (
                <Dialog
                    as={Fragment}
                    open={isModalOpen}
                    onClose={closeModal}
                    className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75"
                >
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0" />
                        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
                        <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            <button
                                onClick={closeModal}
                                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                            >
                                Close
                            </button>
                            {selectedItem && (
                                <>
                                    <ProductView data={selectedItem} />


                                </>
                            )}
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    );
}