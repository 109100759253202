import {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {GetUsers} from "../routes/routes";
import toast from "react-hot-toast";




/**
 * Represents a UserEmail object.
 *
 * @function UserEmail
 * @returns {object} - An object containing properties and methods related to the UserEmail.
 */
export const UserEmail = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [msg, setMsg] = useState('');



    /**
     * Asynchronously creates a user account using the provided email address.
     *
     * @param {Object} e - The event object.
     *
     * @returns {Promise<void>} - A Promise that resolves when the account is successfully created.
     */
    const CreateAccount = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(GetUsers(),
                {
                    email: email,

                });

            console.log("account has successfully been created", response)
            navigate("/");
            if (response) {
                // console.log("authentication is a success")
                toast.success('thanks for signing up',{
                    duration: 8000 // Set duration here (e.g., 8000ms = 8s)
                });
            }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
                console.log(error.data.response)
                toast.error(error.response, {
                    duration: 8000 // Set duration here (e.g., 8000ms = 8s)
                });
            }
        }
    }
    // Return required values
    return {
        email,
        msg,
        setEmail,
        CreateAccount
    };
}