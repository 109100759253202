import React from 'react';
// Import the SCSS file
import '../components/HeroIcon.scss';

// this is our flashing jumping arrow on the front page.
const HeroIcon = ({ icon: Icon, name }) => {
  return(
    <div className="hero-icon">
      <Icon className="icon" />
      <h2>{name}</h2>
    </div>
  );
};

export default HeroIcon;

